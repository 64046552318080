import OriginalsSection from '@/components/Home/Sections/GamesBaseSection/Originals';
import styles from './styles.module.scss';
import RecommendedSection from '@/components/Home/Sections/GamesBaseSection/Recommended';
import dynamic from 'next/dynamic';

const NewReleasesSection = dynamic(
  () => import('@/components/Home/Sections/GamesBaseSection/Releases'),
  { ssr: false },
);
const LiveCasinoeSection = dynamic(
  () => import('@/components/Home/Sections/GamesBaseSection/LiveCasino'),
  { ssr: false },
);

export default function GameLobby() {
  return (
    <section className={styles.container}>
      <RecommendedSection />
      <OriginalsSection />
      <LiveCasinoeSection />
      <NewReleasesSection />
    </section>
  );
}
